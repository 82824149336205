import { useEffect } from 'react';
import './App.css';

function App() {

  useEffect(() => {
    setTimeout(() => {
      gotToScreen('offline');
      document.title = 'ThomasYaz';
    }, 1000)
  })
  return (
    <div className="App">
      <Loader />
    </div>
  );
}

export default App;

function Loader() {
  return (
    <span class="loader"></span>
  )
}

export function gotToScreen(name) {
  window.location.hash = "/" + name;
}