import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import App from "./App";
import Home from "./Screens/Home";
import Error from "./Screens/Error";
import Offline from "./Screens/Offline";
import Projects from "./Screens/Projects";
import Contact from "./Screens/Contact";

ReactDOM.render(
  <HashRouter>
    <Routes>
      {/*
      <Route path="/" element={<App />} />
      <Route path="/home" element={<Home />} />
      <Route path="/offline" element={<Offline />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<Error />} />
*/}
      <Route path="/" element={<App />} />
      <Route path="*" element={<Offline />} />
    </Routes>
  </HashRouter>,
  document.getElementById("root")
);
