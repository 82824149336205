import { useEffect } from 'react';
import './CSS/Error.css';
import img from '../Ressources/Images/astronaut4.png';

function Error() {

    useEffect(() => {
        document.title = 'Got lost?';
    })

    return (
        <div className='Error'>
            <img src={img} className='img1' />
            <div className='error-container'>
                <h1 className='error-404'>404</h1>
                <h1 className='error-title'>Feeling lost there buddy?</h1>
                <p className='error-p'>Seems like you went looking for a page that doesn't exist. Or maybe I really screwed up. <br /> Either way, lets get you <span className='back' onClick={() => document.location.pathname = '/'}>HOME</span></p>
            </div>
        </div>
    );
}

export default Error;