import { useEffect } from 'react';
import './CSS/Offline.css';
import img from '../Ressources/Images/astronaut5.png';
import { AiOutlineCopy } from 'react-icons/ai';
import { BsCheck2Circle } from 'react-icons/bs';

function Offline() {

    useEffect(() => {
        document.title = 'Offline';
    })

    const textCopied = () => {
        navigator.clipboard.writeText("thomas.yazitzoglou@gmail.com");
        document.getElementById('copied').style.opacity = '1';
        setTimeout(() => {
            document.getElementById('copied').style.opacity = '0';
        }, 1000)
    }
    return (
        <div className='Offline'>
            <h1 className='offline-title'>Oops! Site's offline.</h1>
            <img src={img} className='offline-img' />
            <h2 className='offline-subtitle'>We'll be back soon I promise. <br /> If you need to get in touch shoot me an email at:</h2>
            <h3 className='offline-email'>thomas.yazitzoglou@gmail.com <AiOutlineCopy className='offline-icon' onClick={() => textCopied()} /> </h3>

            <h4 className='offline-copied' id='copied'>Text copied <BsCheck2Circle className='offline-copied-icon' /></h4>

        </div>
    );
}

export default Offline;